import { useUI } from '@components/ui'
import { useTranslation } from 'next-i18next'
interface props {}
const UnitSwitcher = ({}: props) => {
  const { setCalculatorUnit, calculatorUnit } = useUI()
  const { t } = useTranslation('product')
  return (
    <div className="flex justify-center pb-2">
      <button
        className={`body-2 w-16 rounded-l py-1 px-3 ${
          calculatorUnit == 'feet'
            ? 'border-t border-l border-b border-ui-dark-grey bg-ui-dark-grey text-white'
            : 'border-t border-l border-b border-ui-grey-25 bg-white text-ui-dark-grey'
        }`}
        onClick={() => {
          setCalculatorUnit('feet')
        }}
      >
        Feet
      </button>
      <button
        className={`body-2 w-16 rounded-r py-1 px-3 ${
          calculatorUnit == 'metres'
            ? 'border-t border-r border-b border-ui-dark-grey bg-ui-dark-grey text-white'
            : 'border-t border-r border-b border-ui-grey-25 bg-white text-ui-dark-grey'
        }`}
        onClick={() => setCalculatorUnit('metres')}
      >
        {t('meters')}
      </button>
    </div>
  )
}
export default UnitSwitcher
