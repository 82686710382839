import { Cross } from '@components/icons'
import WYSIWYG from '@components/ui/WYSIWYG'
import { PrismicDocument } from '@prismicio/types'
interface Props {
  onClose?: () => void
  prismicContent?: PrismicDocument
}
const GardenPotModal = ({ onClose, prismicContent }: Props) => {
  const { gardenPotPaintModalText, gardenPotPaintModalTitle } =
    prismicContent?.data || {}
  return (
    <>
      <div className="h-full max-w-152 pt-8 pb-7 px-6 ">
        <div
          onClick={onClose}
          className="md:hidden right-3 top-3 absolute p-2 text-brand-dark-grey"
        >
          <Cross />
        </div>
        {gardenPotPaintModalTitle && (
          <div className="label-bold uppercase text-center">
            {gardenPotPaintModalTitle}
          </div>
        )}
        {gardenPotPaintModalText && (
          <div className="body-1 flex flex-col gap-4 pt-6">
            <WYSIWYG content={gardenPotPaintModalText} />
          </div>
        )}
      </div>
    </>
  )
}
export default GardenPotModal
