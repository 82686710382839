import { Add } from '@components/icons'
import { Button, useUI } from '@components/ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import CalculatorInput from '../CalculatorInput'
import UnitSwitcher from '../UnitSwitcher'
interface Props {
  onClose: () => void
}
const Calculator = ({ onClose }: Props) => {
  const { closeCalculator, calculatorUnit } = useUI()
  const [inputId, setInputId] = useState(1)
  const [totalCoverage, setTotalCoverage] = useState(0)
  const [calculatorInputs, setCalculatorInputs] = useState([
    { id: inputId, width: undefined, height: undefined, coverage: 0 },
  ])
  const [hover, setHover] = useState(false)
  const handleClose = () => {
    onClose()
    closeCalculator(Math.ceil(totalCoverage))
  }
  const [coverage, setCoverage] = useState(0)
  const { t } = useTranslation('product')
  useEffect(() => {
    setTotalCoverage(
      calculatorInputs.reduce((n, { coverage }) => n + coverage, 0)
    )
  }, [coverage, calculatorInputs])
  useEffect(() => {
    setCalculatorInputs([
      { id: inputId, width: undefined, height: undefined, coverage: 0 },
    ])
  }, [calculatorUnit])
  const handleAdd = () => {
    setCalculatorInputs([
      ...calculatorInputs,
      {
        id: inputId + 1,
        width: undefined,
        height: undefined,
        coverage: 0,
      },
    ])
    setInputId(inputId + 1)
  }
  const removeByIndex = (int: any) => {
    let inputs = calculatorInputs.filter((n) => n.id !== int)
    setCalculatorInputs(inputs)
  }

  const handleHover = () => {
    setHover(true)
  }
  const handleLeave = () => {
    setHover(false)
  }
  const unit = () => {
    switch (calculatorUnit) {
      case 'metres':
        return 'm'
      case 'feet':
        return 'ft'
      default:
        return ''
    }
  }

  return (
    <>
      <div className="max-w-screen-sm px-0 pt-4 pb-2 md:px-6 md:pt-8 md:pb-7">
        <div className="label-bold text-center uppercase">
          {t('calculator')}
        </div>
        <div className="body-1 pt-2 pb-4 text-center md:pt-6 md:pb-6 ">
          {t('calculator-title')}
        </div>
        <UnitSwitcher />
        <div
          className={`max-h-[300px] overflow-y-scroll lg:max-h-[400px] ${
            calculatorInputs.length < 5 && 'scrollbar-hide'
          }`}
        >
          {calculatorInputs.map((input, index) => {
            const handleOnHeightChange = (v: any) => {
              const newArr = calculatorInputs.map((obj) => {
                if (obj.id === input.id) {
                  return {
                    ...obj,
                    height: v,
                    coverage: (input.width || 0) * v,
                  }
                }
                return obj
              })
              setCalculatorInputs(newArr)
            }
            const handleOnWidthChange = (v: any) => {
              const newArr = calculatorInputs.map((obj) => {
                if (obj.id === input.id) {
                  return {
                    ...obj,
                    width: v,
                    coverage: (input.height || 0) * v,
                  }
                }
                return obj
              })
              setCalculatorInputs(newArr)
            }
            const handleRemove = () => {
              removeByIndex(input.id)
            }
            return (
              <CalculatorInput
                width={input.width!}
                height={input.height!}
                id={index + 1}
                onWidthChange={handleOnWidthChange}
                onHeightChange={handleOnHeightChange}
                onRemove={handleRemove}
                key={index}
              />
            )
          })}
        </div>

        <div className="flex items-center border-b-[1px] border-ui-grey-25 pt-4 pb-8 md:pt-6">
          <div
            className="cursor-pointer"
            onMouseOver={handleHover}
            onMouseLeave={handleLeave}
            onClick={handleAdd}
          >
            {hover ? <Add hover /> : <Add />}
          </div>
          <div className="body-1 pl-3">{t('add-another-surface')}</div>
        </div>
        <div className="flex flex-col pt-4 pb-6 md:pt-6">
          <div className="mb-3 flex justify-between">
            <div className="body-1 text-brand-dark-grey">
              {t('coverage-needed')}
            </div>
            <div className="body-1-bold text-brand-dark-grey">
              {`${Math.floor(totalCoverage)} ${unit()}² ${t('with')} 2 ${t(
                'coats'
              )}`}
            </div>
          </div>
        </div>
        <div>
          <Button className="w-full" onClick={handleClose}>
            {t('confirm')}
          </Button>
        </div>
      </div>
    </>
  )
}
export default Calculator
