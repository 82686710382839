interface Props {
  hover?: boolean
}
const Add = ({ hover }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {hover ? (
        <>
          <path
            d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
            fill="#646464"
          />
          <path
            d="M12.5 5H11.5V11.5H5V12.5H11.5V19H12.5V12.5H19V11.5H12.5V5Z"
            fill="white"
          />
        </>
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM11.5 11.5V5H12.5V11.5H19V12.5H12.5V19H11.5V12.5H5V11.5H11.5Z"
          fill="#646464"
        />
      )}
    </svg>
  )
}

export default Add
