import { useUI } from '@components/ui'
import cn from 'clsx'
import { useTranslation } from 'next-i18next'
import s from './CalculatorInput.module.css'
interface InputProps {
  id: number
  onWidthChange?: (...args: any[]) => any
  onHeightChange?: (...args: any[]) => any
  onRemove?: (...args: any[]) => any
  width: number
  height: number
}
const CalculatorInputNew = ({
  id,
  onWidthChange,
  onHeightChange,
  onRemove,
  width,
  height,
}: InputProps) => {
  const { calculatorUnit } = useUI()
  const rootClassName = cn(s.root)
  const unit = () => {
    switch (calculatorUnit) {
      case 'metres':
        return '(m)'
      case 'feet':
        return '(ft)'
      default:
        return ''
    }
  }
  const { t } = useTranslation('product')
  const { t: tcommon } = useTranslation('common')
  const handleOnWidthChange = (e: any) => {
    if (onWidthChange) {
      onWidthChange(e.target.value)
    }
  }
  const handleOnHeightChange = (e: any) => {
    if (onHeightChange) {
      onHeightChange(e.target.value)
    }
  }
  return (
    <>
      <div className="label-bold pb-2 pt-2">{`${t('surface')} ${id}`}</div>
      <div className="flex items-center gap-4">
        <div className={rootClassName}>
          {/* <div className="inline-block relative after:absolute after:top-1 after:right-2 after:content-['cm']"> */}
          <input
            className="overflow-hidden border-0 bg-primary text-center outline-0"
            placeholder="0.0"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            type="number"
            min="0"
            inputMode="numeric"
            pattern="[0-9]*"
            onChange={handleOnWidthChange}
            value={width ? width : ''}
          ></input>
          <span className="px-2 lg:px-0">{unit()}</span>
        </div>
        <div className={rootClassName}>
          <input
            className="overflow-hidden border-0 bg-primary text-center outline-0"
            placeholder="0.0"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            type="number"
            min="0"
            inputMode="numeric"
            pattern="[0-9]*"
            onChange={handleOnHeightChange}
            value={height ? height : ''}
          ></input>
          <span className="px-2 lg:px-0">{unit()}</span>
        </div>
        <button
          // disabled={index == 0}
          onClick={onRemove}
          className="body-2 ml-auto cursor-pointer underline"
        >
          {tcommon('remove')}
        </button>
      </div>
    </>
  )
}
export default CalculatorInputNew
